.onboarding-form {
    .ant-form-item {
        margin-bottom: 1.93rem;
        
        .input-label {
            font-size: 1.143rem;
            margin-bottom: 0.5rem;
            color: #151515;
            font-weight: 400;
        }
    
        .ant-input {
            border: 1px solid #979797;
            width: 19.138rem;
            height: 2.286rem;
            font-size: 1rem;
            font-weight: 400;
        }

        .ant-btn {
            width: 19.143rem;
            height: 2.857rem;
            border: none !important;
            background: #36C626;
            color: #fff;
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 2rem;

            svg {
                width: 0.97rem;
                height: 0.82rem;
                vertical-align: text-top;

                * {
                    fill: #fff;
                }
            }
        }
    }
    .ant-form-item:last-of-type {
        margin-bottom: 0;
    }
}

.onboarding-tooltip {
    .ant-tooltip-arrow {
        top: 50%;
        margin-top: -10px;
        left: 0px;
        border-width: 10px 8.16px 10px 0;
        border-right-color: rgba(0, 0, 0, 0.75);
    }
    .ant-tooltip-inner {
        padding: 0.643rem 1.286rem;
        font-size: 0.857rem;
        background: #3C3C3C;
        color: #FFFFFF;
        width: 15.642rem;
    }
}