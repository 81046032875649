.timer-card {
  .timer-actions {
    ul {
      list-style-type: none;
      display: flex;
      align-content: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;
    }
  }
}
