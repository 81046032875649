.trigger {
  .main-header {
    h1 {
      margin-bottom: 4px;
    }
    p {
      margin-bottom: 5px;
      font-family: "Poppins";
      font-weight: 600;
      font-size: 15px;
    }
    span {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 12px;
    }
  }
  p {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 15px;
  }
  .trigger-form {
    .evergreen {
      display: flex;
      margin-bottom: 20px;
      .inner-timer {
        flex: 1;
      }
      .spanClass {
        color: red;
      }
    }
    .ant-select {
      width: 100%;
    }
    button {
      border: none;
      background: none;
      cursor: pointer;
      :focus {
        outline: unset;
      }
    }
  }
}
button:focus {
  outline: unset;
}
