.login-form {
  height: 100%;
  .form-button {
    display: block;
    background: #083b56;
    color: #ffffff;
    font-size: 1rem;
    text-align: center;
    width: 100%;
    border: none;
    outline: none;
  }
}
