.last-30-day-stats {
    padding-top: 3rem;
    h1 {
        font-size: 20px;
        font-family: 'Poppins-Medium';
        line-height: 24px;
    }

    .ant-card {
        .ant-card-body {
            padding: 0rem;
            padding-top: 0.5rem;
            .ant-tabs-tab {
                font-size: 16px;
                font-family: 'Poppins-Medium';
            }

            .ant-tabs-bar.ant-tabs-top-bar {
                padding-left: 3rem;
                padding-right: 3rem;
            }

            .g2-tooltip-list-item::before {
                content: "\2022";
                color: red;
                font-weight: 600;
                font-size: 1.5rem;
                border-radius: 50%;
                margin-right: 0.25rem;
            }

            .placeholder {
                text-align: center;
                padding: 2rem;

                p {
                    font-size: 2rem;
                    font-weight: 700;
                }
            }
        }
    }
}