.onboarding-container {
    background: url('../../assets/images/backgrounds/Onboarding.svg');
    background-size: cover;
    background-position-y: bottom;
    background-attachment: fixed;

    .onboarding {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 4.143rem;

        .logo {
            width: 14.57rem;
            height: 3.71rem;
            margin-bottom: 1.643rem;
        }

        .progress-bar {
            position: relative;
            width: 22.21rem;
            height: 1.43rem;
            border-radius: 10px;
            background: #EDEDED;
            margin-bottom: 4.143rem;
        }
        .progress-bar::after {
            content: '';
            position: absolute;
            left: 0;
            background: #36C626;
            border-radius: 10px;
            width: 8rem;
            height: 1.43rem;
            z-index: 9;
        }

        .title {
            color: #020429;
            font-size: 1.43rem;
            font-weight: 500;
            margin-bottom: 0.43rem;
        }

        .sub-title {
            font-size: 1.286rem;
            color: #8C8C8C;
            font-weight: 400;
            margin-bottom: 2rem;
        }
    }
}

.ant-modal.onboarding-modal {
    .ant-modal-content {
        border-radius: 3px;

        .ant-modal-body {
            padding: 3.36rem 3.786rem;
            display: flex;
            flex-direction: column;
            align-items: center;

            .heading {
                font-size: 1.714rem;
                font-weight: 500;
                margin-bottom: 1.93rem;
                color: rgba(0, 0, 0, 0.85);
            }

            .ant-btn {
                background: #36C626;
                color: #fff;
                outline: none;
                font-size: 1rem;
                font-weight: 600;
                width: 20.71rem;
                height: 2.86rem;
                outline: none !important;

                .anticon {
                    svg {
                        width: 0.968rem;
                        height: 0.82rem;
                        margin-bottom: 0;
                        vertical-align: text-top;

                        * {
                            fill: #fff;
                        }
                    }
                }
            }
            .ant-btn:hover,
            .ant-btn:active,
            .ant-btn:focus,
            .ant-btn:focus-within {
                border-color: #36C626;
            }
        }
    }
}
.ant-modal.onboarding-modal.welcome-modal {
    .ant-modal-content {
        .ant-modal-body {
            svg {
                width: 10rem;
                height: 10rem;
                margin-block-end: 1.71rem;
            }

            .heading {
                color: #020429;
                font-size: 1.786rem;
                font-weight: 600;
            }

            p.para {
                font-size: 1.14rem;
                text-align: center;
                font-weight: 400;
                margin-bottom: 1rem;
                color: #8C8C8C;

                img {
                    width: 1.286rem;
                    vertical-align: text-bottom;
                }

                .name {
                    text-transform: capitalize;
                }
            }
            p.para-3 {
                color: #464646;
            }

            .below-button {
                font-size: 0.86rem;
                color: #8C8C8C;
                font-weight: 400;
                margin-top: 0.286rem;
            }
        }
    }
}
.ant-modal.onboarding-modal.onboarding-success-modal,
.ant-modal.onboarding-modal.onboarding-failure-modal {
    .ant-modal-content {
        .ant-modal-body {
            padding: 3.214rem 3.357rem;

            svg {
                width: 5.14rem;
                height: 5.14rem;
                margin-bottom: 2.21rem;
            }

            .disclaimer {
                font-size: 1rem;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
                text-align: center;
                margin-bottom: 0;
            }

            .below-disclaimer {
                margin-top: 2.07rem;
            }
        }
    }
}