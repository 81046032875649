.profile-settings {
    .profile-form {
        color: #000;

      .column {
          padding: 1.5rem;
          padding-bottom: 0;
      }

      .form-labels {
          margin: 0;
          font-size: 1.14rem;
          color: #151515;
      }

      .button-container {
          text-align: right;

          .ant-btn-primary {
              background: #92c15f;
              border-color: #92c15f;
          }
          .ant-btn-primary:hover, .ant-btn-primary:focus {
              background: #69bd42;
              border-color: #69bd42;
          }
      }
      @media (max-width: 576px) {
          .button-container {
              text-align: center;
          }
      }

      .ant-divider-horizontal {
          margin: 1rem 0;
      }
    }
}