.btn-green {
  color: white;
  background: #36c626;
  border-color: #36c626;
  border-radius: 4px;
  min-width: 169px;
}
.btn-green:hover,
.btn-green:active,
.btn-green:focus {
  color: white;
  background: #60d155;
  border-color: #60d155;
  border-radius: 4px;
  min-width: 169px;
}
