.overall-stats {
    .column {
        .ant-card{
            color: black;
            height: 122px;
            width: 226px;
            border: none;
            box-shadow: 0 3px 6px rgba(0,0,0, .16);
            border-radius: 2px;
            .ant-card-extra{
                svg{
                    fill:#8d8d8d;
                }
               
            }
            .ant-card-head {
                border-bottom: none;
                color: #8d8d8d;
                font-size: 14px;
                line-height: 22px;
                font-family: 'Poppins-Medium';
            }
            .ant-card-body{
                padding: 0px 24px;
                .label {
                    font-size: 30px;
                    margin-bottom: 0px;
                }
            }
        }
    }
    @media(max-width: 991px) {
        .column {
            padding-bottom: 1rem;
        }
    }
}