.side-img-col {
    background: url('../../../assets/images/backgrounds/loginbg.png');
    background-size: cover;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;

    .callout {
        max-width: 50%;
        position: absolute;
        left: 82px;
        opacity: 0;
    }
    .callout-1 {
        bottom: 210px;
    }
    .slide-up-fade-in{
        animation: slide-up-fade-in ease 1s;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;
        animation-fill-mode:forwards; /*when the spec is finished*/

        opacity:0;
    }

    @keyframes slide-up-fade-in{
        0% {
            opacity:0;
            transform:  translate(0px,40px)  ;
        }
        100% {
            opacity:1;
            transform:  translate(0px,0px)  ;
        }
    }
    .callout-2 {
        bottom: 70px;
        animation-delay: 1s;
    }

}