.pieslice-container {
    padding-top: 2.092rem;

    .table {
        padding: 0 3rem;
        margin-top: 3rem;
        overflow: scroll;
        max-height: 20rem;
        margin-bottom: 3.17rem;

        .heading, .data {
            display: flex;
            justify-content: space-between;
        }
        .heading {
            position: sticky;
            top: 0;
            background: white;
        }

        .heading p,
        .data p {
            font-size: 1.071rem;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            text-transform: capitalize;
            margin-bottom: 1.68rem;
        }

        .data p {
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            margin-bottom: 1.52rem;
        }
    }
}