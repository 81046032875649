.forgot-password-form {
    height: 100%;
    .sub-heading {
        font-size: 1.43rem;
        text-align: center;
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .form-button {
        display: inline-block;
        background: #1890ff;
        color: #FFFFFF;
        font-size: 1rem;
        text-align: center;
        width: 50%;
        border: none;
        outline: none;
    }

    .anticon.anticon-left-circle {
        font-size: 2rem;
    }
}