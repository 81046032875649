html, body {
    min-width: 1280px;
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
font-face{
    font-family: "Poppins-Medium";
    src: url(/static/media/Poppins-Medium.ba95810b.ttf);
}
font-face{
    font-family: "Poppins-Regular";
    src: url(/static/media/Poppins-Regular.41e8dead.ttf);
}
font-face{
    font-family: "Poppins-Semibold";
    src: url(/static/media/Poppins-SemiBold.342ba3d8.ttf);
}
#root, .content-body {
    height: 100%
}
html {
    font-size: 12px;
}
@media screen and (min-width: 320px) {
    html {
        font-size: calc(12px + 4 * ((100vw - 320px) / 961));
    }
}
@media screen and (min-width: 1281px) {
    html {
        font-size: 16px;
    }
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
    text-decoration: none !important;
}

.ant-card {
    border-radius: 4px;
    box-shadow: 0rem 0.21rem 0.42rem 0 rgba(0, 0, 0, 0.16);
}
.ant-tabs-tab {
    font-size: 1.14rem;
}
.ant-tabs-tab-active,
.ant-tabs-tab:active,
.ant-tabs-tab:hover {
    color: #23202C !important;
}
.ant-tabs-ink-bar {
    background: #23202C;
}

.ant-pagination-item-active,
.ant-pagination-item:hover,
.ant-pagination-item:active {
    background: #FF5858 !important;
    border-color: #FF5858 !important;
    outline: none !important;
}
.ant-pagination-item a {
    color: rgba(0, 0, 0, 0.65);
}
.ant-pagination-item-active a,
.ant-pagination-item:hover a,
.ant-pagination-item:active a {
    color: #FFFFFF !important;
}

.delete-button,
.delete-button:hover,
.delete-button:active {
    background: #FFFFFF !important;
    color: #F84B3D !important;
    border-color: #F84B3D !important;
    outline: none !important;
    font-size: 0.86rem;
}

.ant-skeleton,
.ant-alert-error {
    width: 90%;
    margin: auto;
}

.ant-modal-mask {
    background: rgba(2, 4, 41, 0.9);
}
.login-form {
  height: 100%; }
  .login-form .form-button {
    display: block;
    background: #083b56;
    color: #ffffff;
    font-size: 1rem;
    text-align: center;
    width: 100%;
    border: none;
    outline: none; }

.form-container {
  padding: 5rem 2rem 0 2rem;
  height: 100%; }
  .form-container .logo {
    margin: auto;
    width: 13rem;
    display: block; }
  .form-container .tagline {
    text-align: center;
    margin-top: 0.7rem;
    font-weight: 300; }
  .form-container .content {
    padding-top: 3rem; }
    .form-container .content .heading {
      color: #083b56;
      font-size: 1.43rem;
      text-align: center;
      font-weight: 400; }
    .form-container .content .heading-separator {
      border-color: #083b56;
      margin: auto;
      width: 45%;
      margin-bottom: 1.5rem; }
    .form-container .content .ant-form-item {
      width: 80%;
      margin: auto;
      margin-bottom: 1rem;
      font-size: 1rem; }
    .form-container .content .ant-input {
      border-color: #d9d9d9; }
    .form-container .content .flex-div .ant-form-item-children {
      display: -webkit-flex;
      display: flex;
      font-size: 1rem;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
    .form-container .content .flex-div .gap {
      -webkit-flex: 1 1;
              flex: 1 1; }
  .form-container .footer {
    position: -webkit-sticky;
    position: sticky;
    top: 100%;
    padding-bottom: 30px; }
    .form-container .footer .links {
      display: -webkit-flex;
      display: flex;
      margin-left: 30%;
      width: 50%; }
      .form-container .footer .links a {
        -webkit-flex: 1 1;
                flex: 1 1; }
    .form-container .footer .copyright-text {
      margin-left: 32%;
      width: 50%; }

.side-img-col {
  background: url(/static/media/loginbg.7d36ddb9.png);
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat; }
  .side-img-col .callout {
    max-width: 50%;
    position: absolute;
    left: 82px;
    opacity: 0; }
  .side-img-col .callout-1 {
    bottom: 210px; }
  .side-img-col .slide-up-fade-in {
    -webkit-animation: slide-up-fade-in ease 1s;
            animation: slide-up-fade-in ease 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    /*when the spec is finished*/
    opacity: 0; }

@-webkit-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, 40px);
            transform: translate(0px, 40px); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); } }

@keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, 40px);
            transform: translate(0px, 40px); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); } }
  .side-img-col .callout-2 {
    bottom: 70px;
    -webkit-animation-delay: 1s;
            animation-delay: 1s; }

.forgot-password-form {
  height: 100%; }
  .forgot-password-form .sub-heading {
    font-size: 1.43rem;
    text-align: center;
    padding-left: 5rem;
    padding-right: 5rem; }
  .forgot-password-form .form-button {
    display: inline-block;
    background: #1890ff;
    color: #FFFFFF;
    font-size: 1rem;
    text-align: center;
    width: 50%;
    border: none;
    outline: none; }
  .forgot-password-form .anticon.anticon-left-circle {
    font-size: 2rem; }

.signup-form-container {
  min-height: 100%;
  background: url(/static/media/setUpbackground.edc12876.png);
  background-position-y: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem;
  position: relative; }
  .signup-form-container svg.logo {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    max-width: 100%; }
  .signup-form-container .signup-card {
    width: 700px;
    max-width: 100%;
    margin: auto;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0; }
    .signup-form-container .signup-card .ant-card-body {
      padding: 2.57rem; }
      .signup-form-container .signup-card .ant-card-body .title {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 0;
        color: #3a3543;
        font-weight: 500; }
      .signup-form-container .signup-card .ant-card-body .sub-title {
        text-align: center;
        font-size: 1.143rem;
        color: #000;
        font-weight: lighter; }
      .signup-form-container .signup-card .ant-card-body .signup-form {
        padding-top: 2.143rem; }
        .signup-form-container .signup-card .ant-card-body .signup-form .flex {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
                  justify-content: space-between;
          -webkit-align-items: flex-start;
                  align-items: flex-start; }
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .ant-form-item,
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .form-button,
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .disclaimer {
            width: 48%;
            margin-bottom: 0.36rem; }
        .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item {
          display: block;
          margin-top: 0.71rem;
          margin-bottom: 1.07rem; }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input {
            border: 2px solid #cdcdcc;
            border-radius: 4px;
            min-height: 40px; }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:hover,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:active,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus-within {
            border-color: #4d4d4d;
            box-shadow: 0 0 0 2px rgba(77, 77, 77, 0); }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper {
            display: block; }
            .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper span {
              color: #4d4d4d;
              font-size: 1rem;
              font-weight: lighter; }
        .signup-form-container .signup-card .ant-card-body .signup-form .has-error input {
          border-color: #ff1c1c !important; }
        .signup-form-container .signup-card .ant-card-body .signup-form .label {
          font-size: 1rem;
          font-weight: lighter;
          color: #808080; }
        .signup-form-container .signup-card .ant-card-body .signup-form .checkbox-label {
          color: #868686;
          font-size: 1rem;
          font-weight: 400;
          font-style: italic;
          display: block; }
        .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer {
          font-weight: 500; }
          .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer a {
            color: #ff806f !important; }
        .signup-form-container .signup-card .ant-card-body .signup-form .form-button {
          font-size: 1.86rem;
          background: #00db6e;
          border-color: #00db6e;
          outline: none;
          padding: 16px 10%;
          text-transform: capitalize;
          height: 5.5rem;
          max-height: 100%;
          font-weight: 600;
          border-radius: 10px; }
      .signup-form-container .signup-card .ant-card-body .bottom-link {
        color: #868686;
        font-size: 0.86rem;
        font-weight: 500;
        text-align: center;
        margin: 1rem 0 0 0; }
        .signup-form-container .signup-card .ant-card-body .bottom-link a {
          color: #ff806f !important; }
  .signup-form-container .onboarding-footer {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }

.signup-password-popover-content h5 {
  color: #535353;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.1rem; }

.signup-password-popover-content p {
  color: #868686;
  font-size: 1rem;
  margin-bottom: 0.1rem; }
  .signup-password-popover-content p svg {
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom; }

.onboarding-footer {
  position: -webkit-sticky;
  position: sticky;
  top: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .onboarding-footer div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 11.786rem; }
    .onboarding-footer div a {
      color: rgba(0, 0, 0, 0.45) !important;
      text-decoration: none !important;
      font-size: 1rem;
      font-weight: 400; }
  .onboarding-footer .copyright-text {
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.857rem;
    font-weight: 400; }

.forgot-password-form {
  height: 100%; }
  .forgot-password-form .sub-heading {
    font-size: 1.43rem;
    text-align: center;
    padding-left: 5rem;
    padding-right: 5rem; }
  .forgot-password-form .form-button {
    display: inline-block;
    background: #1890ff;
    color: #FFFFFF;
    font-size: 1rem;
    text-align: center;
    width: 50%;
    border: none;
    outline: none; }
  .forgot-password-form .anticon.anticon-left-circle {
    font-size: 2rem; }

.signup-form-container {
  min-height: 100%;
  background: url(/static/media/setUpbackground.edc12876.png);
  background-position-y: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem;
  position: relative; }
  .signup-form-container svg.logo {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    max-width: 200px; }
  .signup-form-container .signup-card {
    width: 700px;
    max-width: 100%;
    margin: auto;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0; }
    .signup-form-container .signup-card .ant-card-body {
      padding: 2.57rem; }
      .signup-form-container .signup-card .ant-card-body .title {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 0;
        color: #3a3543;
        font-weight: 500; }
      .signup-form-container .signup-card .ant-card-body .sub-title {
        text-align: center;
        font-size: 1.143rem;
        color: #000;
        font-weight: lighter; }
      .signup-form-container .signup-card .ant-card-body .signup-form {
        padding-top: 2.143rem; }
        .signup-form-container .signup-card .ant-card-body .signup-form .flex {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
                  justify-content: space-between;
          -webkit-align-items: flex-start;
                  align-items: flex-start; }
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .ant-form-item,
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .form-button,
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .disclaimer {
            width: 48%;
            margin-bottom: 0.36rem; }
        .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item {
          display: block;
          margin-top: 0.71rem;
          margin-bottom: 1.07rem; }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input {
            border: 2px solid #cdcdcc;
            border-radius: 4px;
            min-height: 40px; }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:hover,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:active,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus-within {
            border-color: #4d4d4d;
            box-shadow: 0 0 0 2px rgba(77, 77, 77, 0); }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper {
            display: block; }
            .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper span {
              color: #4d4d4d;
              font-size: 1rem;
              font-weight: lighter; }
        .signup-form-container .signup-card .ant-card-body .signup-form .has-error input {
          border-color: #ff1c1c !important; }
        .signup-form-container .signup-card .ant-card-body .signup-form .label {
          font-size: 1rem;
          font-weight: lighter;
          color: #808080; }
        .signup-form-container .signup-card .ant-card-body .signup-form .checkbox-label {
          color: #868686;
          font-size: 1rem;
          font-weight: 400;
          font-style: italic;
          display: block; }
        .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer {
          font-weight: 500; }
          .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer a {
            color: #ff806f !important; }
        .signup-form-container .signup-card .ant-card-body .signup-form .form-button {
          font-size: 1.86rem;
          background: #00db6e;
          border-color: #00db6e;
          outline: none;
          padding: 16px 10%;
          text-transform: capitalize;
          height: 5.5rem;
          max-height: 100%;
          font-weight: 600;
          border-radius: 10px; }
      .signup-form-container .signup-card .ant-card-body .bottom-link {
        color: #868686;
        font-size: 0.86rem;
        font-weight: 500;
        text-align: center;
        margin: 1rem 0 0 0; }
        .signup-form-container .signup-card .ant-card-body .bottom-link a {
          color: #ff806f !important; }
  .signup-form-container .onboarding-footer {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }

.signup-password-popover-content h5 {
  color: #535353;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.1rem; }

.signup-password-popover-content p {
  color: #868686;
  font-size: 1rem;
  margin-bottom: 0.1rem; }
  .signup-password-popover-content p svg {
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom; }

.ant-layout {
  background: #FCFAFF; }
  .ant-layout .ant-layout-has-sider {
    height: 100%; }

.ant-card {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-color: #edf2f9;
  border-radius: 0.3rem; }

.navbar {
  padding: 0 1.5rem 0 3rem;
  z-index: 1001;
  background: -webkit-linear-gradient(left, #085077, #083a54);
  background: linear-gradient(90deg, #085077, #083a54);
  font-weight: 600;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .navbar .left-menu {
    -webkit-flex: 99999 1;
            flex: 99999 1;
    padding-left: 30px; }
  .navbar .right-menu {
    -webkit-flex: 0 0;
            flex: 0 0; }
    .navbar .right-menu .ant-menu-item:last-child {
      padding-right: 0; }
  .navbar .navbar-ham {
    display: none;
    color: white;
    font-size: 1.71rem; }
  .navbar .logo {
    -webkit-flex: 0 0;
            flex: 0 0; }
    .navbar .logo img {
      max-width: 150px;
      height: 41px;
      padding-bottom: 0.25rem; }
  .navbar .ant-menu-dark {
    background: transparent;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .navbar .ant-menu-dark .ant-menu-item {
      padding: 9px 20px; }
      .navbar .ant-menu-dark .ant-menu-item :nth-child(4) svg * {
        stroke: white !important; }
      .navbar .ant-menu-dark .ant-menu-item a {
        font-size: 14px;
        letter-spacing: 0.3px;
        color: white;
        font-family: "Poppins-Medium"; }
      .navbar .ant-menu-dark .ant-menu-item svg * {
        stroke: transparent; }
      .navbar .ant-menu-dark .ant-menu-item .user-name {
        text-transform: capitalize; }
      .navbar .ant-menu-dark .ant-menu-item .user-status-tag {
        margin-left: 0.25rem; }
      .navbar .ant-menu-dark .ant-menu-item .anticon {
        font-size: 20px;
        margin-right: 0; }
        .navbar .ant-menu-dark .ant-menu-item .anticon svg {
          position: relative;
          top: 2px; }
      .navbar .ant-menu-dark .ant-menu-item .ant-avatar {
        background-color: #fc4747;
        border: 2px solid white;
        box-sizing: content-box;
        text-transform: capitalize;
        font-size: 1.57rem; }
      .navbar .ant-menu-dark .ant-menu-item .avatar-details {
        display: inline-block;
        margin: 0 0.75rem;
        font-size: 0.93rem;
        line-height: 20px;
        vertical-align: middle;
        text-transform: capitalize;
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 0.625rem; }
        .navbar .ant-menu-dark .ant-menu-item .avatar-details .domain-name {
          font-size: 0.78rem; }
    .navbar .ant-menu-dark .ant-menu-item-selected,
    .navbar .ant-menu-dark .ant-menu-item-active,
    .navbar .ant-menu-dark .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .navbar .ant-menu-dark .ant-menu.ant-menu-dark .ant-menu-item-active,
    .navbar .ant-menu-dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
    .navbar .ant-menu-dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active {
      background: #096190;
      color: white; }

.navbar-user-dropdown {
  min-width: 205px;
  padding: 2.2px; }
  .navbar-user-dropdown p {
    margin-bottom: 0; }
  .navbar-user-dropdown .current-account-details {
    font-size: 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; }
    .navbar-user-dropdown .current-account-details .account-name {
      margin-right: 0.71rem;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-flex: 999 1;
              flex: 999 1; }
    .navbar-user-dropdown .current-account-details .ant-tag {
      border-radius: 9px;
      margin-right: 0; }
  .navbar-user-dropdown .switch-account-label {
    font-size: 0.85rem;
    margin-top: 0.71rem; }
  .navbar-user-dropdown .ant-dropdown-menu-item {
    font-size: 1rem;
    padding: 15px 20px;
    overflow: hidden;
    text-overflow: ellipsis; }
    .navbar-user-dropdown .ant-dropdown-menu-item .anticon {
      margin-left: 8px;
      display: none; }
  .navbar-user-dropdown .ant-dropdown-menu-item:hover {
    color: #096190 !important;
    background: #b7e3fa; }
    .navbar-user-dropdown .ant-dropdown-menu-item:hover .anticon {
      display: inline-block; }

.ant-dropdown-link svg {
  font-size: 13px; }

li.ant-menu-item.report-icon svg * {
  stroke: white !important; }

@media (max-width: 768px) {
  .header .ant-menu {
    display: none; }
  .header.ant-layout-header .navbar-ham {
    display: block;
    float: right; } }

.app-footer {
  position: -webkit-sticky;
  position: sticky;
  top: 100%;
  padding: 1rem 0; }
  .app-footer ul {
    list-style: none;
    padding: 0; }
    .app-footer ul li {
      display: inline-block;
      vertical-align: middle;
      margin-right: 1.57rem; }
      .app-footer ul li a {
        color: #979797 !important;
        font-weight: 500; }
      .app-footer ul li img {
        width: 8rem; }

.switch-account-modal .switch-account-text .title {
  color: #5D5C5C; }

.switch-account-modal .switch-account-text Button {
  display: block;
  width: 100%;
  min-height: 3rem;
  text-align: left;
  /* padding: initial; */
  font-size: 1rem;
  border: 2px solid #979797;
  margin-bottom: 1rem; }
  .switch-account-modal .switch-account-text Button span {
    width: 90%; }
    .switch-account-modal .switch-account-text Button span .ant-tag {
      margin-left: 15px; }
  .switch-account-modal .switch-account-text Button svg {
    width: 1rem;
    height: 1rem; }

.switch-account-modal .switch-account-text Button:hover {
  font-weight: 500;
  background: #E5DDFF;
  color: #5D5C5C; }
  .switch-account-modal .switch-account-text Button:hover svg {
    width: 1.3rem;
    height: 1.3rem; }

.no-table-data {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 2.11rem 0; }
  .no-table-data svg {
    width: 21.51rem;
    height: 16.44rem;
    max-width: 100%;
    margin-right: 1.36rem; }
  .no-table-data .text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 24.21rem; }
    .no-table-data .text .heading {
      font-size: 1.43rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0.36rem; }
    .no-table-data .text .content {
      text-align: left;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0;
      font-size: 1.143rem;
      font-weight: 400; }

.main-heading-bar {
  padding: 3rem 0 2rem 0;
  color: #000; }
  .main-heading-bar .title {
    font-size: 25px;
    font-family: "Poppins-Medium";
    line-height: 22px;
    margin-bottom: 0; }
  .main-heading-bar .subtitle {
    font-size: 1.1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 0; }
  .main-heading-bar .page-cta {
    margin-top: 6px; }
  .main-heading-bar .actions {
    text-align: right; }
    .main-heading-bar .actions .ant-btn {
      background: #6cbc26;
      color: #fff;
      outline: none !important;
      border-color: #6cbc26 !important;
      border-radius: 4px;
      font-size: 1rem;
      font-family: "Poppins-Medium";
      height: 40px;
      width: 185px; }

.switch-account-modal .switch-account-text .title {
  color: #5D5C5C; }

.switch-account-modal .switch-account-text Button {
  display: block;
  width: 100%;
  min-height: 3rem;
  text-align: left;
  /* padding: initial; */
  font-size: 1rem;
  border: 2px solid #979797;
  margin-bottom: 1rem; }
  .switch-account-modal .switch-account-text Button span {
    width: 90%; }
    .switch-account-modal .switch-account-text Button span .ant-tag {
      margin-left: 15px; }
  .switch-account-modal .switch-account-text Button svg {
    width: 1rem;
    height: 1rem; }

.switch-account-modal .switch-account-text Button:hover {
  font-weight: 500;
  background: #E5DDFF;
  color: #5D5C5C; }
  .switch-account-modal .switch-account-text Button:hover svg {
    width: 1.3rem;
    height: 1.3rem; }

.snapshot {
  background: #FCFAFF; }

.elements .ant-card {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  height: 120px;
  padding: 10px; }
  .elements .ant-card .ant-card-body {
    height: 100px;
    background: #f8f8f8;
    display: -webkit-flex;
    display: flex;
    padding: 24px; }
    .elements .ant-card .ant-card-body .linkInfo {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .elements .ant-card .ant-card-body .linkInfo h6 {
        font-size: 20px;
        font-family: 'Poppins-Semibold';
        margin-bottom: 0px; }
      .elements .ant-card .ant-card-body .linkInfo p {
        margin-bottom: 0px;
        font-size: 18px;
        font-family: 'Poppins-Medium';
        color: #5d5d5d;
        margin-left: 10px; }
      .elements .ant-card .ant-card-body .linkInfo a {
        color: #EA4E46;
        font-size: 14px;
        font-family: 'Poppins-Medium';
        letter-spacing: .5px; }
        .elements .ant-card .ant-card-body .linkInfo a svg {
          stroke: #EA4E46;
          font-size: 20px; }

.overall-stats .column .ant-card {
  color: black;
  height: 122px;
  width: 226px;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 2px; }
  .overall-stats .column .ant-card .ant-card-extra svg {
    fill: #8d8d8d; }
  .overall-stats .column .ant-card .ant-card-head {
    border-bottom: none;
    color: #8d8d8d;
    font-size: 14px;
    line-height: 22px;
    font-family: 'Poppins-Medium'; }
  .overall-stats .column .ant-card .ant-card-body {
    padding: 0px 24px; }
    .overall-stats .column .ant-card .ant-card-body .label {
      font-size: 30px;
      margin-bottom: 0px; }

@media (max-width: 991px) {
  .overall-stats .column {
    padding-bottom: 1rem; } }

.last-30-day-stats {
  padding-top: 3rem; }
  .last-30-day-stats h1 {
    font-size: 20px;
    font-family: 'Poppins-Medium';
    line-height: 24px; }
  .last-30-day-stats .ant-card .ant-card-body {
    padding: 0rem;
    padding-top: 0.5rem; }
    .last-30-day-stats .ant-card .ant-card-body .ant-tabs-tab {
      font-size: 16px;
      font-family: 'Poppins-Medium'; }
    .last-30-day-stats .ant-card .ant-card-body .ant-tabs-bar.ant-tabs-top-bar {
      padding-left: 3rem;
      padding-right: 3rem; }
    .last-30-day-stats .ant-card .ant-card-body .g2-tooltip-list-item::before {
      content: "\2022";
      color: red;
      font-weight: 600;
      font-size: 1.5rem;
      border-radius: 50%;
      margin-right: 0.25rem; }
    .last-30-day-stats .ant-card .ant-card-body .placeholder {
      text-align: center;
      padding: 2rem; }
      .last-30-day-stats .ant-card .ant-card-body .placeholder p {
        font-size: 2rem;
        font-weight: 700; }

.timer-card .timer-actions ul {
  list-style-type: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 0;
  padding: 0; }

.sort button {
  border: none;
  background: #fcfaff;
  box-shadow: unset; }

.campaign-report .ant-card {
  background: #fff;
  border-radius: 2px;
  border: none;
  box-shadow: none;
  margin-bottom: 40px; }

.campaign-report .hourwise-stats-card .ant-card-body {
  padding-bottom: 0; }

.campaign-report .pie-chart-cards {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .campaign-report .pie-chart-cards .ant-card .ant-card-head {
    border-bottom: 0; }
    .campaign-report .pie-chart-cards .ant-card .ant-card-head .ant-card-head-title {
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      font-size: 1.21rem;
      padding: 1.43rem 0; }
  .campaign-report .pie-chart-cards .ant-card .ant-card-body {
    padding-top: 0; }
  .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll {
    text-align: center; }
    .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav {
      border: 1px solid #d9d9d9;
      margin-bottom: 1.19rem;
      border-radius: 4px; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab {
        padding: 0.48rem 1.71rem;
        margin: 0;
        font-size: 1.07rem;
        color: #595959; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab-active {
        color: #fff !important;
        background: #1890ff;
        border-color: #1890ff;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab:last-of-type.ant-tabs-tab-active {
        border-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px; }
      .campaign-report .pie-chart-cards .ant-card .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-ink-bar {
        display: none !important; }

.hourwise-stats-tabs .g2-tooltip-list-item::before {
  content: "\2022";
  color: #2D6F26;
  font-weight: 600;
  font-size: 1.5rem;
  border-radius: 50%;
  margin-right: 0.25rem; }

.daywise-stats-tabs .g2-tooltip-list-item::before {
  content: "\2022";
  color: #8E3030;
  font-weight: 600;
  font-size: 1.5rem;
  border-radius: 50%;
  margin-right: 0.25rem; }


.pieslice-container {
  padding-top: 2.092rem; }
  .pieslice-container .table {
    padding: 0 3rem;
    margin-top: 3rem;
    overflow: scroll;
    max-height: 20rem;
    margin-bottom: 3.17rem; }
    .pieslice-container .table .heading, .pieslice-container .table .data {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
    .pieslice-container .table .heading {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background: white; }
    .pieslice-container .table .heading p,
    .pieslice-container .table .data p {
      font-size: 1.071rem;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      text-transform: capitalize;
      margin-bottom: 1.68rem; }
    .pieslice-container .table .data p {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 1.52rem; }



.countrywise-stats-container .ant-tabs-tabpane {
  padding: 0 0.786rem;
  margin-bottom: 2.21rem; }

.countrywise-stats-container .table {
  padding: 0 0.786rem; }
  .countrywise-stats-container .table .content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px solid #E8E8E8; }
    .countrywise-stats-container .table .content p {
      -webkit-flex: 0.3 1;
              flex: 0.3 1;
      padding-left: 50px;
      font-size: 1.07rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 1.73rem;
      padding-top: 1.486rem; }
    .countrywise-stats-container .table .content p:last-of-type {
      padding-left: 0px;
      padding-right: 50px;
      text-align: right; }
    .countrywise-stats-container .table .content p.middle {
      text-align: center; }
  .countrywise-stats-container .table .content:first-of-type {
    background: #FAFAFA;
    padding-top: 1rem;
    border-bottom: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
    .countrywise-stats-container .table .content:first-of-type p {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 1.36rem;
      padding-top: 0; }
  .countrywise-stats-container .table .content:last-of-type {
    border-bottom: 0; }

.btn-green {
  color: white;
  background: #36c626;
  border-color: #36c626;
  border-radius: 4px;
  min-width: 169px; }

.btn-green:hover,
.btn-green:active,
.btn-green:focus {
  color: white;
  background: #60d155;
  border-color: #60d155;
  border-radius: 4px;
  min-width: 169px; }

.general-setting .main-header h1 {
  margin-bottom: 4px; }

.general-setting .main-header p {
  margin-bottom: 5px; }

.general-setting .main-header span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px; }

.general-setting .general-form .ant-select {
  width: 100%; }

.general-setting .general-form .evergreen {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly; }
  .general-setting .general-form .evergreen .inner-timer {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .general-setting .general-form .evergreen .inner-timer .ant-input-number {
      width: 96%; }
  .general-setting .general-form .evergreen .inner-timer1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }

.general-setting .general-form .spanclass {
  color: red; }

.general-setting .general-form .ant-calendar-picker {
  width: 100% !important; }

.general-setting p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 15px; }

.trigger .main-header h1 {
  margin-bottom: 4px; }

.trigger .main-header p {
  margin-bottom: 5px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 15px; }

.trigger .main-header span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px; }

.trigger p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 15px; }

.trigger .trigger-form .evergreen {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 20px; }
  .trigger .trigger-form .evergreen .inner-timer {
    -webkit-flex: 1 1;
            flex: 1 1; }
  .trigger .trigger-form .evergreen .spanClass {
    color: red; }

.trigger .trigger-form .ant-select {
  width: 100%; }

.trigger .trigger-form button {
  border: none;
  background: none;
  cursor: pointer; }
  .trigger .trigger-form button :focus {
    outline: unset; }

button:focus {
  outline: unset; }

.onboarding-container {
  background: url(/static/media/Onboarding.41fd81a9.svg);
  background-size: cover;
  background-position-y: bottom;
  background-attachment: fixed; }
  .onboarding-container .onboarding {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 4.143rem; }
    .onboarding-container .onboarding .logo {
      width: 14.57rem;
      height: 3.71rem;
      margin-bottom: 1.643rem; }
    .onboarding-container .onboarding .progress-bar {
      position: relative;
      width: 22.21rem;
      height: 1.43rem;
      border-radius: 10px;
      background: #EDEDED;
      margin-bottom: 4.143rem; }
    .onboarding-container .onboarding .progress-bar::after {
      content: '';
      position: absolute;
      left: 0;
      background: #36C626;
      border-radius: 10px;
      width: 8rem;
      height: 1.43rem;
      z-index: 9; }
    .onboarding-container .onboarding .title {
      color: #020429;
      font-size: 1.43rem;
      font-weight: 500;
      margin-bottom: 0.43rem; }
    .onboarding-container .onboarding .sub-title {
      font-size: 1.286rem;
      color: #8C8C8C;
      font-weight: 400;
      margin-bottom: 2rem; }

.ant-modal.onboarding-modal .ant-modal-content {
  border-radius: 3px; }
  .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body {
    padding: 3.36rem 3.786rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .heading {
      font-size: 1.714rem;
      font-weight: 500;
      margin-bottom: 1.93rem;
      color: rgba(0, 0, 0, 0.85); }
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn {
      background: #36C626;
      color: #fff;
      outline: none;
      font-size: 1rem;
      font-weight: 600;
      width: 20.71rem;
      height: 2.86rem;
      outline: none !important; }
      .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn .anticon svg {
        width: 0.968rem;
        height: 0.82rem;
        margin-bottom: 0;
        vertical-align: text-top; }
        .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn .anticon svg * {
          fill: #fff; }
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:hover,
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:active,
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:focus,
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:focus-within {
      border-color: #36C626; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body svg {
  width: 10rem;
  height: 10rem;
  -webkit-margin-after: 1.71rem;
          margin-block-end: 1.71rem; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body .heading {
  color: #020429;
  font-size: 1.786rem;
  font-weight: 600; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para {
  font-size: 1.14rem;
  text-align: center;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #8C8C8C; }
  .ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para img {
    width: 1.286rem;
    vertical-align: text-bottom; }
  .ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para .name {
    text-transform: capitalize; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para-3 {
  color: #464646; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body .below-button {
  font-size: 0.86rem;
  color: #8C8C8C;
  font-weight: 400;
  margin-top: 0.286rem; }

.ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body,
.ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body {
  padding: 3.214rem 3.357rem; }
  .ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body svg,
  .ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body svg {
    width: 5.14rem;
    height: 5.14rem;
    margin-bottom: 2.21rem; }
  .ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body .disclaimer,
  .ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body .disclaimer {
    font-size: 1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    margin-bottom: 0; }
  .ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body .below-disclaimer,
  .ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body .below-disclaimer {
    margin-top: 2.07rem; }

.onboarding-form .ant-form-item {
  margin-bottom: 1.93rem; }
  .onboarding-form .ant-form-item .input-label {
    font-size: 1.143rem;
    margin-bottom: 0.5rem;
    color: #151515;
    font-weight: 400; }
  .onboarding-form .ant-form-item .ant-input {
    border: 1px solid #979797;
    width: 19.138rem;
    height: 2.286rem;
    font-size: 1rem;
    font-weight: 400; }
  .onboarding-form .ant-form-item .ant-btn {
    width: 19.143rem;
    height: 2.857rem;
    border: none !important;
    background: #36C626;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 2rem; }
    .onboarding-form .ant-form-item .ant-btn svg {
      width: 0.97rem;
      height: 0.82rem;
      vertical-align: text-top; }
      .onboarding-form .ant-form-item .ant-btn svg * {
        fill: #fff; }

.onboarding-form .ant-form-item:last-of-type {
  margin-bottom: 0; }

.onboarding-tooltip .ant-tooltip-arrow {
  top: 50%;
  margin-top: -10px;
  left: 0px;
  border-width: 10px 8.16px 10px 0;
  border-right-color: rgba(0, 0, 0, 0.75); }

.onboarding-tooltip .ant-tooltip-inner {
  padding: 0.643rem 1.286rem;
  font-size: 0.857rem;
  background: #3C3C3C;
  color: #FFFFFF;
  width: 15.642rem; }

.btn-green {
  color: white;
  background: #36c626;
  border-color: #36c626;
  border-radius: 4px;
  min-width: 169px; }

.btn-green:hover,
.btn-green:active,
.btn-green:focus {
  color: white;
  background: #60d155;
  border-color: #60d155;
  border-radius: 4px;
  min-width: 169px; }

.general-setting .main-header h1 {
  margin-bottom: 4px; }

.general-setting .main-header p {
  margin-bottom: 5px; }

.general-setting .main-header span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px; }

.general-setting .general-form .ant-select {
  width: 100%; }

.general-setting .general-form .evergreen {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly; }
  .general-setting .general-form .evergreen .inner-timer {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .general-setting .general-form .evergreen .inner-timer .ant-input-number {
      width: 96%; }
  .general-setting .general-form .evergreen .inner-timer1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }

.general-setting .general-form .spanclass {
  color: red; }

.general-setting .general-form .ant-calendar-picker {
  width: 100% !important; }

.general-setting p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 15px; }

.trigger .main-header h1 {
  margin-bottom: 4px; }

.trigger .main-header p {
  margin-bottom: 5px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 15px; }

.trigger .main-header span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px; }

.trigger p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 15px; }

.trigger .trigger-form .evergreen {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 20px; }
  .trigger .trigger-form .evergreen .inner-timer {
    -webkit-flex: 1 1;
            flex: 1 1; }
  .trigger .trigger-form .evergreen .spanClass {
    color: red; }

.trigger .trigger-form .ant-select {
  width: 100%; }

.trigger .trigger-form button {
  border: none;
  background: none;
  cursor: pointer; }
  .trigger .trigger-form button :focus {
    outline: unset; }

button:focus {
  outline: unset; }

.pixel-install-options .click-to-copy-btn {
  background: #36C626;
  border-color: #36C626;
  color: #fff !important;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 3px;
  height: 2.5rem;
  margin: 1rem 0 2rem 0;
  padding: 0.25rem 2rem 0rem 2rem; }
  .pixel-install-options .click-to-copy-btn .anticon svg {
    width: 1.25rem;
    height: 1.25rem; }
    .pixel-install-options .click-to-copy-btn .anticon svg * {
      fill: #fff !important; }
  .pixel-install-options .click-to-copy-btn .anticon + span {
    margin-left: 0.25rem; }

.pixel-install-options .click-to-copy-btn:hover,
.pixel-install-options .click-to-copy-btn:active,
.pixel-install-options .click-to-copy-btn:focus,
.pixel-install-options .click-to-copy-btn:focus-within {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }

.pixel-install-options .disclaimer {
  font-size: 1.14rem;
  color: #979797; }

.pixel-install-options .disclaimer.wordpress-text {
  margin-bottom: 0; }

.pixel-install-options textarea.ant-input {
  font-size: 1rem;
  padding: 0.75rem 1rem 1rem 1rem; }

.pixel-install-options .pixel-btn {
  background: #36C626;
  border-color: #36C626;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 3px;
  height: 2.5rem;
  margin: 1rem 0 2rem 0;
  padding: 0rem 2rem 0rem 2rem; }
  .pixel-install-options .pixel-btn .anticon svg {
    width: 1.25rem;
    height: 1.25rem; }
    .pixel-install-options .pixel-btn .anticon svg * {
      fill: #fff !important; }
  .pixel-install-options .pixel-btn .anticon + span {
    margin-left: 0.25rem; }

.pixel-install-options .pixel-btn:hover,
.pixel-install-options .pixel-btn:active,
.pixel-install-options .pixel-btn:focus,
.pixel-install-options .pixel-btn:focus-within {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }

.pixel-install-options .pixel-btn.wordpess-button {
  padding-top: 0.25rem; }


.profile-settings .profile-form {
  color: #000; }
  .profile-settings .profile-form .column {
    padding: 1.5rem;
    padding-bottom: 0; }
  .profile-settings .profile-form .form-labels {
    margin: 0;
    font-size: 1.14rem;
    color: #151515; }
  .profile-settings .profile-form .button-container {
    text-align: right; }
    .profile-settings .profile-form .button-container .ant-btn-primary {
      background: #92c15f;
      border-color: #92c15f; }
    .profile-settings .profile-form .button-container .ant-btn-primary:hover, .profile-settings .profile-form .button-container .ant-btn-primary:focus {
      background: #69bd42;
      border-color: #69bd42; }
  @media (max-width: 576px) {
    .profile-settings .profile-form .button-container {
      text-align: center; } }
  .profile-settings .profile-form .ant-divider-horizontal {
    margin: 1rem 0; }

