.switch-account-modal {
  .switch-account-text {
    .title {
        color: #5D5C5C;
    }

    Button {
        display: block;
        width: 100%;
        min-height: 3rem;
        text-align: left;
        /* padding: initial; */
        font-size: 1rem;
        border: 2px solid #979797;
        margin-bottom: 1rem;

        span {
            width: 90%;
            .ant-tag {
                margin-left: 15px;
            }
        }

        svg {
            width: 1rem;
            height: 1rem;
        }
    }
    Button:hover {
        font-weight: 500;
        background: #E5DDFF;
        color: #5D5C5C;
        svg {
            width: 1.3rem;
            height: 1.3rem;
        }
    }
  }
}