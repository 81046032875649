.countrywise-stats-container {
    .ant-tabs-tabpane {
        padding: 0 0.786rem;
        margin-bottom: 2.21rem;
    }

    .table {
        padding: 0 0.786rem;

        .content {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #E8E8E8;

            p {
                flex: 0.3;
                padding-left: 50px;
                font-size: 1.07rem;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
                margin-bottom: 1.73rem;
                padding-top: 1.486rem;
            }
            p:last-of-type {
                padding-left: 0px;
                padding-right: 50px;
                text-align: right;
            }
            p.middle {
                text-align: center;
            }
        }
        .content:first-of-type {
            background: #FAFAFA;
            padding-top: 1rem;
            // margin-bottom: 1.41rem;
            border-bottom: 0;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            
            p {
                font-size: 1rem;
                font-weight: 500;
                margin-bottom: 1.36rem;
                padding-top: 0;
            }
        }
        .content:last-of-type {
            border-bottom: 0;
        }
    }
}