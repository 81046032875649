.general-setting {
  .main-header {
    h1 {
      margin-bottom: 4px;
    }
    p {
      margin-bottom: 5px;
    }
    span {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 12px;
    }
  }
  .general-form {
    .ant-select {
      width: 100%;
    }
    .evergreen {
      display: flex;
      justify-content: space-evenly;
      .inner-timer {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        .ant-input-number {
          width: 96%;
        }
      }
      .inner-timer1 {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .spanclass {
      color: red;
    }
    .ant-calendar-picker {
      width: 100% !important;
    }
  }
  p {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 15px;
  }
}
