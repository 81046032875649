.ant-layout {
    background: #FCFAFF;
    .ant-layout-has-sider {
        height: 100%;
    }
}
.ant-card {
    box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
    border-color: #edf2f9;
    border-radius: 0.3rem;
}