.main-heading-bar {
  padding: 3rem 0 2rem 0;
  color: #000;

  .title {
    font-size: 25px;
    font-family: "Poppins-Medium";
    line-height: 22px;
    margin-bottom: 0;
  }
  .subtitle {
    font-size: 1.1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 0;
  }
  .page-cta {
    margin-top: 6px;
  }

  .actions {
    text-align: right;

    .ant-btn {
      background: #6cbc26;
      color: #fff;
      outline: none !important;
      border-color: #6cbc26 !important;
      border-radius: 4px;
      font-size: 1rem;
      font-family: "Poppins-Medium";
      height: 40px;
      width: 185px;
    }
  }
}
