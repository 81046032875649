.snapshot {
    background: #FCFAFF;
}
.elements .ant-card{
    box-shadow: 0 3px 6px rgba(0,0,0, .16);
    border-radius: 2px;
    height:120px;
    padding: 10px;
    .ant-card-body {
        height: 100px;
        background: #f8f8f8;
        display: flex;
        padding: 24px;
        .linkInfo{
            display: flex;
            align-items: center;
            h6{
                font-size: 20px;
                font-family: 'Poppins-Semibold';
                margin-bottom: 0px;
            }
            p {
                margin-bottom: 0px;
                font-size: 18px;
                font-family: 'Poppins-Medium';
                color: #5d5d5d;
                margin-left: 10px;
            }
            a {
                color: #EA4E46;
                font-size: 14px;
                font-family: 'Poppins-Medium';
                letter-spacing: .5px;
                svg{
                    stroke: #EA4E46; 
                    font-size: 20px;
                }
            }
        }
    }
}