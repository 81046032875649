.no-table-data {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.11rem 0;

    svg {
        width: 21.51rem;
        height: 16.44rem;
        max-width: 100%;
        margin-right: 1.36rem;
    }

    .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 24.21rem;

        .heading {
            font-size: 1.43rem;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
            margin-bottom: 0.36rem;
        }

        .content {
            text-align: left;
            color: rgba(0, 0, 0, 0.45);
            margin-bottom: 0;
            font-size: 1.143rem;
            font-weight: 400;
        }
    }
}